import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import * as Yup from 'yup';

import { Form } from '@rocketseat/unform';

import { resendCodeService } from 'services/user';

import ReactLoading from 'react-loading';

import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';

import history from 'services/history';

import queryString from 'query-string';

import VerificationCodeInput from 'components/VerificationCodeInput';
import { RoundedCheckbox } from 'components/Checkbox';

import { Error, FormInput } from 'components/Input';

import { Button } from '@cloudez/cloudez-design-system';

import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InputBox, Content, Wrapper } from './styles';

const TwoFactorAuthentication: React.FC = () => {
  const location = useLocation();
  const theme = useLayout();
  document.title = `2FA — ${theme.name}`;

  const { signIn, loginData, devices, signInError } = useAuth();

  const [isTrustedDevice, setIsTrustedDevice] = useState(false);
  const [codeAuthError, setCodeAuthError] = useState('');
  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState({
    cod1: '',
    cod2: '',
    cod3: '',
    cod4: '',
    cod5: '',
    cod6: '',
  });

  const handleCodeChange = newCode => {
    setCode(newCode);
  };

  const concatCode = useMemo(
    () =>
      `${code.cod1}${code.cod2}${code.cod3}${code.cod4}${code.cod5}${code.cod6}`,
    [code],
  );

  const [switchMode, setSwitchMode] = useState(false);

  const app = devices?.find(device => device === 'app');
  const email = devices?.find(device => device === 'email');

  const [params, setParams] = useState({});

  const resendCodeToEmail = async () => {
    await resendCodeService(loginData);
  };

  useEffect(() => {
    const _params = queryString.parse(location.search);
    const newParams = {} as any;

    for (const key in _params) {
      if (key.startsWith('utm_')) {
        newParams[key] = _params[key];
      }
    }

    const utmReferrer = document.referrer;
    if (!newParams.utm_referrer && utmReferrer) {
      newParams.utm_referrer = utmReferrer;
    }

    if (_params.reseller === 'true') {
      newParams.reseller = 'true';
    }

    setParams(newParams);
  }, []);

  const schema = Yup.object().shape({
    code: Yup.string().required(
      'Verifique o código de segurança e tente novamente.',
    ),
  });

  const handleSubmit = data => {
    schema
      .validate(data, { abortEarly: false })
      .then(async result => {
        setLoading(true);
        await signIn(loginData.email, loginData.password, params, {
          code: Object.values(result).join(''),
          trust_device: isTrustedDevice,
          device_type:
            signInError === 'Verification code sent in email' || switchMode
              ? 'email'
              : 'app',
        });
        setLoading(false);
        setCodeAuthError('');
      })
      .catch(error => {
        setCodeAuthError(error.message);
        setLoading(false);
      });
  };

  const Ref = useRef(null);

  const [timer, setTimer] = useState('00:00');

  const getTimeRemaining = e => {
    const total = Date.parse(e) - Date.parse(new Date() as any);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = e => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        `${minutes > 9 ? minutes : `0${minutes}`}:${
          seconds > 9 ? seconds : `0${seconds}`
        }`,
      );
    }
  };

  const clearTimer = e => {
    setTimer('05:00');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + 300);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const resendAndReset = () => {
    resendCodeToEmail();
    clearTimer(getDeadTime());
  };

  const switchAndSendCode = () => {
    if (!switchMode) {
      resendCodeToEmail();
      clearTimer(getDeadTime());
    }
    setSwitchMode(!switchMode);
  };

  useEffect(() => {
    const inputs = document.querySelectorAll('input');

    inputs.forEach((input, index) => {
      input.addEventListener('input', (e: any) => {
        if (e.target.value.length > 0) {
          if (index !== inputs.length - 1) {
            inputs[index + 1].focus();
          } else {
            inputs[index].blur();
          }
        }
      });
    });
  }, []);

  useEffect(() => {
    if (devices?.length === 0) {
      history.push('/login');
    }
  }, [devices]);

  return (
    <Wrapper>
      <Content>
        {!theme || theme.loading ? (
          <ReactLoading type="balls" />
        ) : (
          <>
            <img src={theme.logo_primary} alt="logo" />
            <Form style={{ padding: '28px' }} onSubmit={handleSubmit}>
              <FontAwesomeIcon
                style={{ color: theme.darkMode ? '#fff' : '#000' }}
                size="3x"
                icon={faLock}
              />
              <span className="sentCode">
                {app
                  ? 'Por favor, insira o código de 6 dígitos que está sendo exibido no seu aplicativo de segurança'
                  : ' Te enviamos um código de 6 dígitos no seu email cadastrado. Digite esse código abaixo para acessar o seu painel'}
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  marginBottom: '25px',
                  width: '100%',
                }}
              >
                <InputBox>
                  <VerificationCodeInput
                    noSpace
                    onCodeChange={handleCodeChange}
                  />
                  <FormInput
                    name="code"
                    style={{ display: 'none' }}
                    value={concatCode}
                    type="text"
                  />
                </InputBox>
                {signInError === 'Verification code failed' || codeAuthError ? (
                  <Error>
                    Verifique o código de segurança e tente novamente.
                  </Error>
                ) : null}
              </div>
              <Button icon width="255px" height="50px" type="submit">
                {loading ? (
                  <FontAwesomeIcon
                    style={{ marginLeft: '0' }}
                    icon={faSpinner}
                    spin
                  />
                ) : (
                  'Acessar painel'
                )}
              </Button>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '15px',
                }}
              >
                <RoundedCheckbox
                  checked={isTrustedDevice}
                  value={isTrustedDevice}
                  onChange={e => setIsTrustedDevice(e.target.checked)}
                  name="trustedDevice"
                  type="checkbox"
                />
                <span
                  onClick={() => setIsTrustedDevice(!isTrustedDevice)}
                  style={{ fontSize: '14px' }}
                  className="sentCode"
                >
                  Lembrar este dispositivo por 30 dias
                </span>
              </div>
              {!app ||
                (switchMode && (
                  <>
                    {timer === '00:00' ? (
                      <span
                        className="sentCode"
                        style={{
                          fontSize: '14px',
                          textDecoration: 'underline',
                        }}
                        onClick={resendAndReset}
                      >
                        Reenviar código
                      </span>
                    ) : (
                      <span
                        className="sentCode"
                        style={{
                          fontSize: '14px',
                          textDecoration: 'underline',
                        }}
                      >
                        Reenviar novamente em {timer}
                      </span>
                    )}
                  </>
                ))}

              {app && email && (
                <span
                  onClick={switchAndSendCode}
                  style={{
                    fontSize: '14px',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}
                  className="sentCode"
                >
                  {switchMode
                    ? 'Não tenho acesso ao email e quero usar o aplicativo'
                    : 'Não tenho acesso ao aplicativo e quero receber por e-mail'}
                </span>
              )}
            </Form>
          </>
        )}
      </Content>
    </Wrapper>
  );
};

export default TwoFactorAuthentication;
